import { Grid } from '@krakentech/coral';
import { FormikTextField } from '@krakentech/coral-formik';
import { TFunction, useTranslation } from 'next-i18next';
import { ReactElement } from 'react';
import * as Yup from 'yup';

import { validationRegex } from '@/components/helpers/validationRegex';
import { CUSTOMER_NAME_MAX_LENGTH } from '@/utils/constants/constants';

type JapanNameFieldsValidation = {
	contractFamilyName: Yup.StringSchema<string | undefined, object>;
	contractFamilyNameKana: Yup.StringSchema<string | undefined, object>;
	contractGivenName: Yup.StringSchema<string | undefined, object>;
	contractGivenNameKana: Yup.StringSchema<string | undefined, object>;
	firstName: Yup.StringSchema<string | undefined, object>;
	firstNameKatakana: Yup.StringSchema<string | undefined, object>;
	lastName: Yup.StringSchema<string | undefined, object>;
	lastNameKatakana: Yup.StringSchema<string | undefined, object>;
};

export const getJapanNameFieldsValidation = (
	t: TFunction
): JapanNameFieldsValidation => {
	return {
		lastName: Yup.string()
			.trim()
			.required(t('errors.required'))
			.max(
				CUSTOMER_NAME_MAX_LENGTH,
				t('errors.invalid-name-length', { number: CUSTOMER_NAME_MAX_LENGTH })
			)
			.matches(validationRegex.isKanjiName, t('errors.invalid-kanji-name')),
		firstName: Yup.string()
			.trim()
			.required(t('errors.required'))
			.matches(validationRegex.isKanjiName, t('errors.invalid-kanji-name'))
			.max(
				CUSTOMER_NAME_MAX_LENGTH,
				t('errors.invalid-name-length', { number: CUSTOMER_NAME_MAX_LENGTH })
			),
		lastNameKatakana: Yup.string()
			.trim()
			.required(t('errors.required'))
			.matches(validationRegex.isKatakana, t('errors.must-be-katakana'))
			.max(
				CUSTOMER_NAME_MAX_LENGTH,
				t('errors.invalid-name-length', { number: CUSTOMER_NAME_MAX_LENGTH })
			),
		firstNameKatakana: Yup.string()
			.trim()
			.required(t('errors.required'))
			.matches(validationRegex.isKatakana, t('errors.must-be-katakana'))
			.max(
				CUSTOMER_NAME_MAX_LENGTH,
				t('errors.invalid-name-length', { number: CUSTOMER_NAME_MAX_LENGTH })
			),
		contractFamilyName: Yup.string()
			.trim()
			.matches(validationRegex.isKanjiName, t('errors.invalid-kanji-name'))
			.max(
				CUSTOMER_NAME_MAX_LENGTH,
				t('errors.invalid-name-length', { number: CUSTOMER_NAME_MAX_LENGTH })
			)
			.when('isContractNameSameAsCustomerName', {
				is: false,
				then: (schema: Yup.StringSchema<string, object>) =>
					schema.required(t('errors.required')),
			}),
		contractGivenName: Yup.string()
			.trim()
			.matches(validationRegex.isKanjiName, t('errors.invalid-kanji-name'))
			.max(
				CUSTOMER_NAME_MAX_LENGTH,
				t('errors.invalid-name-length', { number: CUSTOMER_NAME_MAX_LENGTH })
			)
			.when('isContractNameSameAsCustomerName', {
				is: false,
				then: (schema: Yup.StringSchema<string, object>) =>
					schema.required(t('errors.required')),
			}),
		contractFamilyNameKana: Yup.string()
			.trim()
			.matches(validationRegex.isKatakana, t('errors.must-be-katakana'))
			.max(
				CUSTOMER_NAME_MAX_LENGTH,
				t('errors.invalid-name-length', { number: CUSTOMER_NAME_MAX_LENGTH })
			)
			.when('isContractNameSameAsCustomerName', {
				is: false,
				then: (schema: Yup.StringSchema<string, object>) =>
					schema.required(t('errors.required')),
			}),
		contractGivenNameKana: Yup.string()
			.trim()
			.matches(validationRegex.isKatakana, t('errors.must-be-katakana'))
			.max(
				CUSTOMER_NAME_MAX_LENGTH,
				t('errors.invalid-name-length', { number: CUSTOMER_NAME_MAX_LENGTH })
			)
			.when('isContractNameSameAsCustomerName', {
				is: false,
				then: (schema: Yup.StringSchema<string, object>) =>
					schema.required(t('errors.required')),
			}),
	};
};

export function JapanNameFields<T>(
	props: DomainFieldProps<T> & {
		firstNameKanaInputName?: string;
		firstNameKanjiInputName?: string;
		lastNameKanaInputName?: string;
		lastNameKanjiInputName?: string;
	}
): ReactElement {
	const { t } = useTranslation();
	const {
		lastNameKanjiInputName = 'lastName',
		firstNameKanjiInputName = 'firstName',
		lastNameKanaInputName = 'lastNameKatakana',
		firstNameKanaInputName = 'firstNameKatakana',
	} = props;

	return (
		<>
			<Grid.Item colSpan={2} md={{ colSpan: 1 }}>
				<FormikTextField
					label={t('obj:inputs.last-name')}
					name={lastNameKanjiInputName}
				/>
			</Grid.Item>
			<Grid.Item colSpan={2} md={{ colSpan: 1 }}>
				<FormikTextField
					label={t('obj:inputs.first-name')}
					name={firstNameKanjiInputName}
				/>
			</Grid.Item>
			<Grid.Item colSpan={2} md={{ colSpan: 1 }}>
				<FormikTextField
					label={t('obj:inputs.last-name-kana')}
					name={lastNameKanaInputName}
				/>
			</Grid.Item>
			<Grid.Item colSpan={2} md={{ colSpan: 1 }}>
				<FormikTextField
					label={t('obj:inputs.first-name-kana')}
					name={firstNameKanaInputName}
				/>
			</Grid.Item>
		</>
	);
}
