import * as Sentry from '@sentry/nextjs';
import { useQuery } from 'react-query';

import apiClient from '@/services/api-client';

export const useGMOScriptQuery = (
	enabled: boolean
): {
	gmoScriptObjectURL: string | undefined;
} => {
	const { data } = useQuery(
		['obtainGmoScriptObjectURL'],
		async () => {
			const { script } = await apiClient.get<{ script: string }>(
				'/api/billing/gmo-script'
			);
			return URL.createObjectURL(new Blob([script]));
		},
		{
			enabled,
			refetchOnWindowFocus: false,
			onError: (e) => {
				Sentry.captureMessage('Error occurred in the gmo-script request.', {
					extra: { e },
				});
			},
		}
	);

	return { gmoScriptObjectURL: data };
};
