export const TG_CUSTOMER_NUMBER_PLACEHOLDER = '1xxx-xxx-xxxx';
export const TG_CUSTOMER_NUMBER_LENGTH = TG_CUSTOMER_NUMBER_PLACEHOLDER.length;

export const CURRENT_GAS_SPIN_PLACEHOLDER = '0xx-xxxx-xxxx-xxxx-xx';
export const CURRENT_GAS_SPIN_LENGTH = CURRENT_GAS_SPIN_PLACEHOLDER.length;

export const MAX_OTHER_CUSTOMER_NUM_LENGTH = 40;

export const BUSINESS_DAYS_FOR_GAS_MOVE_IN = 7;

export enum GasSuppliers {
	cdenergy = 'CD エナジーダイレクト',
	eneos = 'ENEOS',
	jcom = 'JCOM',
	nicigas = '日本瓦斯（ニチガス）',
	other = 'その他',
	rakuten = '楽天ガス',
	tepco = '東京電力エナジーパートナー',
	tg = '東京ガス',
	tokyu = '東急パワーサプライ',
}

export const GAS_SUPPLIERS = [
	GasSuppliers.tg,
	GasSuppliers.rakuten,
	GasSuppliers.jcom,
	GasSuppliers.tepco,
	GasSuppliers.tokyu,
	GasSuppliers.eneos,
	GasSuppliers.nicigas,
	GasSuppliers.cdenergy,
	GasSuppliers.other,
] as const;

export const TG_GAS_SUPPLIERS = [
	GasSuppliers.tg,
	GasSuppliers.rakuten,
	GasSuppliers.jcom,
];
