import { addBusinessDays, format, isAfter, isSameDay } from 'date-fns';
import { TFunction } from 'next-i18next';
import * as Yup from 'yup';

import { getAddressFieldsValidation } from '@/components/forms/fields/AddressFields';
import { getBillingAddressFieldsValidation } from '@/components/forms/fields/BillingAddressFields';
import { getCreditCardFieldsValidation } from '@/components/forms/fields/CreditCardFields';
import { getJapanDateFieldValidation } from '@/components/forms/fields/JapanDateField';
import { getJapanNameFieldsValidation } from '@/components/forms/fields/JapanNameFields';
import { getPostcodeFieldValidation } from '@/components/forms/fields/PostcodeField';
import { getSPINFieldValidation } from '@/components/forms/fields/SPINField';
import {
	getCurrentGenerationContractNumberValidation,
	getCurrentSupplierContractNumberValidation,
	getGPINFieldValidation,
	getMobileFieldValidation,
	getOnboardingJourneyEmailFieldValidation,
} from '@/components/forms/validations';
import { validationRegex } from '@/components/helpers/validationRegex';
import {
	GasAttendantOptions,
	GasVisitTimes,
} from '@/components/pages/join/YourDetailsStep/DualFuelFieldsMoveIn';
import {
	BUSINESS_DAYS_FOR_GAS_MOVE_IN,
	GasSuppliers,
	MAX_OTHER_CUSTOMER_NUM_LENGTH,
	TG_GAS_SUPPLIERS,
} from '@/domain/product/dual-fuel/constants';
import { PaymentTypeChoices } from '@/services/typed-graphql-sdk';
import {
	CUSTOMER_NAME_MAX_LENGTH,
	KVA_VALUES,
} from '@/utils/constants/constants';
import { ContractCapacityOption } from '@/utils/constants/industry/industry';

export enum COSGainType {
	moveIn = 'moveIn',
	switchIn = 'switchIn',
}

export enum MarketingCommunicationsOpt {
	in = 'in',
	out = 'out',
}

type BankAccountFieldsValidation = {
	bankAccountBankCode: Yup.StringSchema<string | undefined, object>;
	bankAccountBranchCode: Yup.StringSchema<string | undefined, object>;
	bankAccountHolderName: Yup.StringSchema<string | undefined, object>;
	bankAccountNumber: Yup.StringSchema<string | undefined, object>;
	bankAccountType: Yup.StringSchema<string | undefined, object>;
};

export const getBankAccountFieldsValidation = (
	t: TFunction
): BankAccountFieldsValidation => {
	return {
		bankAccountNumber: Yup.string()
			.matches(
				validationRegex.bankAccountNumber,
				t('errors.invalid-bank-account-number')
			)
			.when('paymentType', {
				is: PaymentTypeChoices.BankAccount,
				then: (schema: Yup.StringSchema<string, object>) =>
					schema.required(t('errors.required')),
			}),
		bankAccountHolderName: Yup.string()
			.trim()
			.matches(
				validationRegex.isValidBankAccountHolderName,
				t('errors.invalid-bank-holder-name')
			)
			.when('paymentType', {
				is: PaymentTypeChoices.BankAccount,
				then: (schema: Yup.StringSchema<string, object>) =>
					schema.required(t('errors.required')),
			}),
		bankAccountBankCode: Yup.string()
			.matches(validationRegex.bankCode, t('errors.invalid-bank-code'))
			.when('paymentType', {
				is: PaymentTypeChoices.BankAccount,
				then: (schema: Yup.StringSchema<string, object>) =>
					schema.required(t('errors.required')),
			}),
		bankAccountBranchCode: Yup.string()
			.matches(validationRegex.bankBranch, t('errors.invalid-bank-branch'))
			.when('paymentType', {
				is: PaymentTypeChoices.BankAccount,
				then: (schema: Yup.StringSchema<string, object>) =>
					schema.required(t('errors.required')),
			}),
		bankAccountType: Yup.string().matches(
			validationRegex.bankAccountType,
			t('errors.missing-bank-account-type')
		),
	};
};

const getJoinFormValidationSchema = (t: TFunction): Yup.ObjectSchema => {
	const AddressFieldsValidation = getAddressFieldsValidation(t);
	const BankAccountFieldsValidation = getBankAccountFieldsValidation(t);
	const BillingAddressFieldsValidation = getBillingAddressFieldsValidation(t);
	const CreditCardFieldsValidation = getCreditCardFieldsValidation(t);
	const OnboardingJourneyEmailFieldValidation =
		getOnboardingJourneyEmailFieldValidation(t);
	const SPINFieldValidation = getSPINFieldValidation(t);
	const JapanDateFieldValidation = getJapanDateFieldValidation(t);
	const JapanNameFieldsValidation = getJapanNameFieldsValidation(t);
	const PostcodeFieldValidation = getPostcodeFieldValidation(t);
	const CurrentSupplierContractNumberValidation =
		getCurrentSupplierContractNumberValidation(t);
	const MobileFieldValidation = getMobileFieldValidation(t);
	return Yup.object().shape({
		usageAmount: Yup.string()
			.required(t('errors.required'))
			.matches(validationRegex.isNumber, t('errors.must-be-number')),
		/**
		 * @todo Implement requiredness with the required prop of DomainField.
		 *  If we start defining requiredness in this schema, we will need to maintain two sources of truth for required fields (required prop, and this schema).
		 **/
		kva: Yup.string().when('contractCapacity', {
			is: ContractCapacityOption.kva,
			then: Yup.string()
				.required(t('errors.required'))
				.test({
					test: (input) => (input ? KVA_VALUES.includes(Number(input)) : true),
					message: t('common:errors.invalid-kva', {
						minValue: Math.min(...KVA_VALUES).toString(),
						maxValue: Math.max(...KVA_VALUES).toString(),
					}),
				}),
		}),
		productCode: Yup.string(),
		moveInDate: JapanDateFieldValidation,
		SPIN: SPINFieldValidation,
		currentSupplierContractNumber: CurrentSupplierContractNumberValidation,
		postcode: PostcodeFieldValidation,
		addressLine1: AddressFieldsValidation.addressLine1,
		buildingName: AddressFieldsValidation.buildingName,
		buildingNumber: AddressFieldsValidation.buildingNumber,
		roomNumber: AddressFieldsValidation.roomNumber,
		firstName: JapanNameFieldsValidation.firstName,
		lastName: JapanNameFieldsValidation.lastName,
		lastNameKatakana: JapanNameFieldsValidation.lastNameKatakana,
		firstNameKatakana: JapanNameFieldsValidation.firstNameKatakana,
		contractFamilyName: JapanNameFieldsValidation.contractFamilyName,
		contractGivenName: JapanNameFieldsValidation.contractGivenName,
		contractFamilyNameKana: JapanNameFieldsValidation.contractFamilyNameKana,
		contractGivenNameKana: JapanNameFieldsValidation.contractGivenNameKana,
		email: OnboardingJourneyEmailFieldValidation,
		mobile: MobileFieldValidation,
		paymentType: Yup.string().required(t('errors.please-select-payment')),
		billingPostcode: PostcodeFieldValidation,
		billingAddressLine1: BillingAddressFieldsValidation.billingAddressLine1,
		billingBuildingName: BillingAddressFieldsValidation.billingBuildingName,
		billingRoomNumber: BillingAddressFieldsValidation.billingRoomNumber,
		billingBuildingNumber: BillingAddressFieldsValidation.billingBuildingNumber,
		cardNumber: CreditCardFieldsValidation.cardNumber,
		cardExpiryDate: CreditCardFieldsValidation.cardExpiryDate,
		cardCVC: CreditCardFieldsValidation.cardCVC,
		bankAccountHolderName: BankAccountFieldsValidation.bankAccountHolderName,
		bankAccountNumber: BankAccountFieldsValidation.bankAccountNumber,
		bankAccountBankCode: BankAccountFieldsValidation.bankAccountBankCode,
		bankAccountBranchCode: BankAccountFieldsValidation.bankAccountBranchCode,
		bankAccountType: BankAccountFieldsValidation.bankAccountType,
		agreeToTermsAndConditions: Yup.boolean().oneOf(
			[true],
			t('errors.agreement-to-terms-and-conditions')
		),
		agreeToKonbiniPayment: Yup.boolean().when('paymentType', {
			is: PaymentTypeChoices.Konbini,
			then: Yup.boolean().oneOf(
				[true],
				t('errors.agreement-to-terms-and-conditions')
			),
		}),
	});
};

/**
 * Adds fields to be validated when signing up for a Generation Product to the joinFormValidationSchema
 * i.e. on the FIT journey
 */

const getGenerationFieldsSchema = (
	t: TFunction,
	joinFormValidationSchema: Yup.ObjectSchema
): Yup.ObjectSchema => {
	const GPINFieldValidation = getGPINFieldValidation(t);
	const CurrentGenerationContractNumberValidation =
		getCurrentGenerationContractNumberValidation(t);
	return (
		joinFormValidationSchema?.shape({
			GPIN: GPINFieldValidation,
			currentGenerationContractNumber:
				CurrentGenerationContractNumberValidation,
			electricityGeneratedLastYear: Yup.string()
				.required(t('errors.required'))
				.matches(validationRegex.isNumber, t('errors.must-be-number')),
			fileAttachmentId: Yup.string().required(t('errors.required')),
		}) ?? {}
	);
};

/**
 * @todo
 * Handle Japan localised public holidays when calculating business days
 * (addBusinessDays only considers Sat, Sun as non-business days)
 */
const getEarliestGasMoveInDate = (): Date =>
	addBusinessDays(new Date(), BUSINESS_DAYS_FOR_GAS_MOVE_IN);

const getDualFuelFieldsSchema = (
	t: TFunction,
	joinFormValidationSchema: Yup.ObjectSchema
): Yup.ObjectSchema =>
	joinFormValidationSchema?.shape({
		gasMoveVisitDate: Yup.string()
			.nullable()
			.when('COSGainType', {
				is: COSGainType.moveIn,
				then: (schema: Yup.StringSchema<string, object>) =>
					schema.required(t('errors.required')).test({
						test: (input) =>
							input
								? isSameDay(new Date(input), getEarliestGasMoveInDate())
									? true
									: isAfter(new Date(input), getEarliestGasMoveInDate())
								: true,
						message: t('errors.must-be-after-date', {
							date: format(getEarliestGasMoveInDate(), 'yyyy/MM/dd'),
						}),
					}),
			}),
		gasMoveVisitTime: Yup.object().when('COSGainType', {
			is: COSGainType.moveIn,
			then: (schema: Yup.StringSchema<string, object>) =>
				schema.test({
					test: (input) => GasVisitTimes.includes(input?.value),
					message: t('errors.required'),
				}),
		}),
		gasMoveVisitAttendantSelection: Yup.object().when('COSGainType', {
			is: COSGainType.moveIn,
			then: (schema: Yup.StringSchema<string, object>) =>
				schema.test({
					test: (input) => GasAttendantOptions.includes(input?.value),
					message: t('errors.required'),
				}),
		}),
		gasMoveVisitAttendantLastNameKanji: Yup.string().when(
			['COSGainType', 'gasMoveVisitAttendantSelection'],
			{
				is: (cosGainType, gasMoveVisitAttendantSelection) =>
					cosGainType === COSGainType.moveIn &&
					gasMoveVisitAttendantSelection?.value !== '1',
				then: Yup.string()
					.trim()
					.required(t('errors.required'))
					.max(
						CUSTOMER_NAME_MAX_LENGTH,
						t('errors.invalid-name-length', {
							number: CUSTOMER_NAME_MAX_LENGTH,
						})
					)
					.matches(validationRegex.isKanjiName, t('errors.invalid-kanji-name')),
			}
		),
		gasMoveVisitAttendantFirstNameKanji: Yup.string().when(
			['COSGainType', 'gasMoveVisitAttendantSelection'],
			{
				is: (cosGainType, gasMoveVisitAttendantSelection) =>
					cosGainType === COSGainType.moveIn &&
					gasMoveVisitAttendantSelection?.value !== '1',
				then: Yup.string()
					.trim()
					.required(t('errors.required'))
					.matches(validationRegex.isKanjiName, t('errors.invalid-kanji-name'))
					.max(
						CUSTOMER_NAME_MAX_LENGTH,
						t('errors.invalid-name-length', {
							number: CUSTOMER_NAME_MAX_LENGTH,
						})
					),
			}
		),
		gasMoveVisitAttendantMobile: Yup.string().when(
			['COSGainType', 'gasMoveVisitAttendantSelection'],
			{
				is: (cosGainType, gasMoveVisitAttendantSelection) =>
					cosGainType === COSGainType.moveIn &&
					gasMoveVisitAttendantSelection?.value !== '1',
				then: getMobileFieldValidation(t),
			}
		),
		gasSwitchCurrentSupplier: Yup.object().when('COSGainType', {
			is: COSGainType.switchIn,
			then: Yup.object().test({
				test: (input) => input?.value,
				message: t('errors.required'),
			}),
		}),
		gasSwitchCurrentSupplierName: Yup.string().when(
			['COSGainType', 'gasSwitchCurrentSupplier'],
			{
				is: (cosGainType, gasSwitchCurrentSupplier) =>
					cosGainType === COSGainType.switchIn &&
					gasSwitchCurrentSupplier?.value === GasSuppliers.other,
				then: Yup.string().required(t('errors.required')),
			}
		),
		gasSwitchCurrentMenu: Yup.object().when(
			['COSGainType', 'gasSwitchCurrentSupplier'],
			{
				is: (cosGainType, gasSwitchCurrentSupplier) =>
					cosGainType === COSGainType.switchIn &&
					TG_GAS_SUPPLIERS.includes(gasSwitchCurrentSupplier?.value),
				then: Yup.object().test({
					test: (input) => input?.value,
					message: t('errors.required'),
				}),
			}
		),
		gasSwitchTokyoGasCustomerNumber: Yup.string().when(
			['COSGainType', 'gasSwitchCurrentSupplier'],
			{
				is: (cosGainType, gasSwitchCurrentSupplier) =>
					cosGainType === COSGainType.switchIn &&
					TG_GAS_SUPPLIERS.includes(gasSwitchCurrentSupplier?.value),
				then: Yup.string()
					.required(t('errors.required'))
					.matches(
						validationRegex.isTokyoGasCustomerNumber,
						t('errors.must-be-tg-number')
					),
			}
		),
		gasSwitchSPIN: Yup.string().when(
			['COSGainType', 'gasSwitchCurrentSupplier'],
			{
				is: (cosGainType, gasSwitchCurrentSupplier) =>
					cosGainType === COSGainType.switchIn &&
					!TG_GAS_SUPPLIERS.includes(gasSwitchCurrentSupplier?.value),
				then: Yup.string()
					.required(t('errors.required'))
					.matches(validationRegex.isGasSPIN, t('errors.must-be-gas-spin')),
			}
		),
		gasSwitchOtherContractNumber: Yup.string().when(
			['COSGainType', 'gasSwitchCurrentSupplier'],
			{
				is: (cosGainType, gasSwitchCurrentSupplier) =>
					cosGainType === COSGainType.switchIn &&
					!TG_GAS_SUPPLIERS.includes(gasSwitchCurrentSupplier?.value),
				then: Yup.string().max(
					MAX_OTHER_CUSTOMER_NUM_LENGTH,
					t('errors.text-length-must-be-less-than-x-characters', {
						number: MAX_OTHER_CUSTOMER_NUM_LENGTH,
					})
				),
			}
		),
	}) ?? {};

export {
	getGenerationFieldsSchema,
	getJoinFormValidationSchema,
	getDualFuelFieldsSchema,
	getEarliestGasMoveInDate,
};
