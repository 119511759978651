import { useReadSessionStorage } from '@/hooks/useReadSessionStorage';
import { SalesChannelChoices } from '@/services/typed-graphql-sdk';

export const useIsDirectChannel = (): boolean => {
	const affiliateSalesChannel = useReadSessionStorage<string | null>(
		'affiliateSalesChannel'
	);
	return (
		affiliateSalesChannel === null ||
		affiliateSalesChannel === SalesChannelChoices.Direct
	);
};
