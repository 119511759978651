import { TFunction } from 'next-i18next';
import * as Yup from 'yup';

import { validationRegex } from '@/components/helpers/validationRegex';
import { featuresConfig } from '@/features/features.config';
import apiClient from '@/services/api-client';
import {
	ValidateEmailMutation,
	ValidatePhoneMutation,
} from '@/services/typed-graphql-sdk';
import { SUPPLIER_CONTRACT_NUMBER_MAX_LENGTH } from '@/utils/constants/constants';
import { memoize } from '@/utils/memoize';

export const getGPINFieldValidation = (
	t: TFunction
): Yup.StringSchema<string | undefined, object> =>
	Yup.string()
		.matches(
			validationRegex.isValidGPIN,
			t('errors.invalid-generation-point-id')
		)
		.required(t('errors.required'));

export const getCurrentGenerationContractNumberValidation = (
	t: TFunction
): Yup.StringSchema<string | undefined, object> =>
	Yup.string()
		.trim()
		.matches(
			validationRegex.isAlphanumeric,
			t('errors.enter-only-alphanumeric')
		)
		.max(
			SUPPLIER_CONTRACT_NUMBER_MAX_LENGTH,
			t('errors.invalid-supplier-contract-number')
		)
		.required(t('errors.required'));

export const getCurrentSupplierContractNumberValidation = (
	t: TFunction
): Yup.StringSchema<string | undefined, object> =>
	Yup.string()
		.trim()
		.matches(
			validationRegex.isAlphanumeric,
			t('errors.enter-only-alphanumeric')
		)
		.max(
			SUPPLIER_CONTRACT_NUMBER_MAX_LENGTH,
			t('errors.invalid-supplier-contract-number')
		)
		.when('COSGainType', {
			is: 'switchIn',
			then: (schema: Yup.StringSchema<string, object>) =>
				schema.required(t('errors.required')),
		});

export const RegularMobileFieldValidation = (
	t: TFunction
): Yup.StringSchema<string | undefined, object> =>
	Yup.string()
		.trim()
		.matches(
			validationRegex.isJapanMobileNumber,
			t('common:errors.must-be-phone-number')
		)
		.matches(
			validationRegex.isMobileStartingDigits,
			t('common:errors.must-be-mobile-number')
		);

export const getMobileFieldValidation = (
	t: TFunction
): Yup.StringSchema<string | undefined, object> =>
	Yup.string()
		.required(t('errors.required'))
		.matches(
			validationRegex.isJapanMobileNumber,
			t('errors.must-be-mobile-number')
		)
		.test({
			name: 'checkPhoneNumberIsValid',
			message: t('errors.invalid-phone-number'),
			async test(phoneNumber) {
				return (
					validationRegex.isJapanMobileNumber.test(phoneNumber) &&
					(await fetchPhoneNumberValidity(phoneNumber).then(
						({ validatePhone }) => Boolean(validatePhone?.isValid)
					))
				);
			},
		});

const fetchPhoneNumberValidity = memoize(async (phoneNumber: number) =>
	apiClient.post<ValidatePhoneMutation>(
		'/api/onboarding/validate-phone-number',
		{
			input: { phoneNumber },
		}
	)
);

export const fetchEmailValidity = memoize(async (email: string) =>
	apiClient.post<ValidateEmailMutation>('/api/onboarding/validate-email', {
		input: { email },
	})
);

export const RegularEmailFieldValidation = (
	t: TFunction
): Yup.StringSchema<string | undefined, object> =>
	Yup.string()
		.trim()
		.matches(
			validationRegex.isBasicLatin,
			t('common:errors.enter-only-basic-latin')
		)
		.matches(validationRegex.isValidEmail, t('common:errors.invalid-email'));

export const getOnboardingJourneyEmailFieldValidation = (
	t: TFunction
): Yup.StringSchema<string | undefined, object> => {
	const validateEmailWithService = async (email: string) => {
		if (featuresConfig.NEXT_PUBLIC_EMAIL_VALIDATION_SERVICE_DISABLED) {
			// eslint-disable-next-line no-console
			console.log('Email validation service is disabled');
			return true;
		}
		return await fetchEmailValidity(email).then(({ validateEmail }) =>
			Boolean(validateEmail?.isValid)
		);
	};

	return Yup.string()
		.required(t('errors.required'))
		.matches(validationRegex.isBasicLatin, t('errors.enter-only-basic-latin'))
		.test({
			name: 'checkEmailIsValid',
			message: t('errors.invalid-email'),
			async test(email) {
				return (
					validationRegex.isValidEmail.test(email) &&
					(await validateEmailWithService(email))
				);
			},
		});
};
