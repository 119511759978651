import { FormikTextField } from '@krakentech/coral-formik';
import { addMonths, parseISO } from 'date-fns';
import { TFunction } from 'next-i18next';
import { ReactElement } from 'react';
import * as Yup from 'yup';

import { CompositionEvent } from '@/types/inputs';
import { DATE_INPUT_LENGTH } from '@/utils/constants/constants';
import { fullWidthToHalfWidth } from '@/utils/formatters/fullWidthToHalfWidth';
import { numberHyphenator9000 } from '@/utils/formatters/numberHyphenator9000';

export const getJapanDateFieldValidation = (
	t: TFunction
): Yup.StringSchema<string | undefined, object> =>
	Yup.string()
		.trim()
		.test({
			message: t('errors.invalid-date'),
			test: (inputDate: unknown): boolean => {
				if (!inputDate) {
					return true;
				}
				const validated = parseISO(String(inputDate).replace(/\//g, '-'));
				return validated.toString() !== 'Invalid Date';
			},
		})
		.when('COSGainType', {
			is: 'moveIn',
			then: (schema: Yup.StringSchema<string, object>) =>
				schema.required(t('errors.required')).test({
					message: t('errors.must-be-within-6-months'),
					test: (inputDate: unknown): boolean => {
						if (!inputDate) {
							return true;
						}
						const date = new Date(inputDate as string);
						return date < addMonths(Date.now(), 6);
					},
				}),
		});

export function JapanDateField<T>(props: DomainFieldProps<T>): ReactElement {
	const { name = 'date', placeholder = 'YYYY/MM/DD', setFieldValue } = props;
	const onChange = (value: string) => {
		const slashedYYYYMMDDDate = numberHyphenator9000(value, [4, 2, 2], '/');
		setFieldValue(name ?? '', slashedYYYYMMDDDate);
	};

	return (
		<FormikTextField
			inputProps={{
				maxLength: DATE_INPUT_LENGTH,
				inputMode: 'numeric',
				onCompositionEndCapture: (e: CompositionEvent) =>
					onChange(fullWidthToHalfWidth(e.target.value, e.target.maxLength)),
			}}
			label={props.label || ''}
			name={name}
			onChange={(e: { target: HTMLInputElement }) => onChange(e.target.value)}
			placeholder={placeholder}
			validate={props.validate}
			onBlur={props.onBlur}
		/>
	);
}
